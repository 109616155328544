import { formatPhone } from '@swe/shared/utils/phone';

import { getRegionAbbreviation } from 'common/entities/get-region-abbreviation';
import { getSaleTypeMsg, createPageMetaBuilder } from 'entities/common/seo';
import { ShopInfo } from 'entities/shop/info';

const getSeo = (dealerName: string | undefined, storeInfo: ShopInfo) => {
  const {
    contacts: { address, phone },
    name,
    location,
    saleTypes,
    fulfillmentTypes,
  } = storeInfo;

  const cityName = location?.city?.name ?? '';
  const stateName = location?.region?.name ?? '';
  const phoneFormatted = formatPhone(phone || '');
  const shortStateName = getRegionAbbreviation(stateName);

  const builder = createPageMetaBuilder({
    finalize: () => {
      builder.description.add([`Visit In-Store ${address}`, `call us ${phoneFormatted}`]);
    },
  });

  return {
    builder,
    payload: {
      storeName: name,
      dealerName,
      cityName,
      stateName,
      shortStateName,
      saleTypesFormatted: getSaleTypeMsg(saleTypes),
      saleTypes,
      address,
      phone,
      phoneFormatted,
      fulfillmentTypesFormatted: fulfillmentTypes.map((type) => (type === 'Delivery' ? 'Free Delivery' : type)),
      // TODO: Obsolete parameters. Remove them later, when all translations are updated
      city: cityName,
      name,
    },
  };
};

export { getSeo };
